@if (options$ | async; as options) {
  @if (schema$ | async; as schema) {
    <smallstack-form-input-container
      [options]="options"
      [schema]="schema"
      [path]="path()"
      [validationErrorText]="validationErrorText()"
    >
      <select
        class="select w-full max-w-xs"
        [ngClass]="{ 'text-error': validationErrorText() !== undefined }"
        [required]="required"
        [ngModel]="value$ | async"
        (ngModelChange)="setValue($event); validateOnChange()"
        (blur)="validateOnBlur()"
        data-test="schema-form-widget-select-theme-color"
      >
        <option disabled selected>Bitte wählen Sie eine Farbe</option>
        <option value="primary">Primäre Farbe</option>
        <option value="secondary">Sekundäre Farbe</option>
        <option value="accent">Akzent Farbe</option>
        <option value="neutral">Neutrale Farbe</option>
      </select>
    </smallstack-form-input-container>
  }
}
