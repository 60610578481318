import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import {
  AxiosApiClient,
  ContactDto,
  ContactsApi,
  CreateNoteDto,
  NoteDto,
  NotePageDto,
  SearchRequestDto
} from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class ContactsStore extends PageableCrudStore<ContactDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
    this.query$.next({ page: 1, size: 1000, sort: "lastName" });
  }

  public async getNotes(contactId: string): Promise<NotePageDto> {
    return (await this.axiosApiClient.get(ContactsApi).getContactNotes({ id: contactId })).data;
  }

  public async createContactNote(contactId: string, createNote: CreateNoteDto): Promise<NoteDto> {
    return (await this.axiosApiClient.get(ContactsApi).createContactNote({ id: contactId, createNote })).data;
  }

  public async performSearch(searchRequest: SearchRequestDto): Promise<Page<ContactDto>> {
    const res = await this.axiosApiClient.get(ContactsApi).performContactSearch({ searchRequest });
    return res.data;
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<ContactDto>> {
    return (await this.axiosApiClient.get(ContactsApi).getContacts(query)).data;
  }

  protected async loadModelById(id: string): Promise<ContactDto> {
    return (await this.axiosApiClient.get(ContactsApi).getContact({ id })).data;
  }

  protected async deleteModelById(id: string): Promise<void> {
    return (await this.axiosApiClient.get(ContactsApi).deleteContact({ id })).data;
  }

  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(ContactsApi).deleteManyContacts({ ids });
    return res.data;
  }

  protected async createModel(contact: ContactDto): Promise<ContactDto> {
    return (await this.axiosApiClient.get(ContactsApi).createContact({ contact })).data;
  }

  protected async patchModel(id: string, contact: Partial<ContactDto>): Promise<ContactDto> {
    return (await this.axiosApiClient.get(ContactsApi).patchContact({ id, contact })).data;
  }

  protected async putModel(contact: ContactDto): Promise<ContactDto> {
    return (await this.axiosApiClient.get(ContactsApi).putContact({ id: contact.id, contact })).data;
  }
}
