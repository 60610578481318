@if (!nativeScannerAvailable) {
  <div id="qr-code" class="flex flex-col space-y-5 justify-center items-center">
    <div fxFlexAlign="end" class="flex flex-row">
      @if (cancelScan.observers.length > 0) {
        <smallstack-icon name="delete-sign" size="24" style="cursor: pointer" (click)="goBack()" />
      }
    </div>
    @if (messageTitle) {
      <div class="flex flex-col justify-center items-center">
        <h3 style="text-align: center">{{ messageTitle }}</h3>
        <p class="mat-caption">{{ messageBody }}</p>
        @if (!isSearchingCamera && !scanPermission) {
          <smallstack-icon alias="no-camera" size="160" color="primary" />
        }
      </div>
    }
    @if (scanPermission) {
      <div style="width: 100%; height: 40%" class="flex flex-col space-y-5 justify-center items-center">
        @if (isSearchingCamera) {
          <mat-spinner color="primary" />
        }
        <zxing-scanner
          #scannerComponent
          [autostart]="true"
          [device]="currentCamera"
          [enable]="true"
          (scanSuccess)="onScannerSuccess($event)"
          (permissionResponse)="onScannerPermissionResponse($event)"
          (camerasFound)="onScannerCamerasFound($event)"
          (camerasNotFound)="onScannerCamerasNotFound()"
          (scanError)="onScannerError('scan error', $event)"
        />
        <p class="mat-caption">Empfohlener Mindestabstand: 20 cm</p>
        @if (scanPermission && cameras && cameras.length > 1) {
          <button class="btn" (click)="changeCamera()">
            <span>Kamera wechseln</span>
          </button>
        }
      </div>
    }
  </div>
}
@if (nativeScannerAvailable) {
  <div>
    <div class="flex flex-col space-y-5 justify-center items-center">
      @if (isScanningNatively) {
        <mat-spinner color="primary" />
      }
      @if (!nativeScannerErrorMessage) {
        <div> <p>Warte auf Barcode Scanner... </p></div>
      }
      @if (nativeScannerErrorMessage) {
        <div>
          <p>{{ nativeScannerErrorMessage }}</p>
          <button class="btn btn-primary" (click)="doNativeScan()">Wiederholen</button>
        </div>
      }
    </div>
  </div>
}
