<smallstack-form-input-container
  [options]="options()"
  [schema]="schema()"
  [path]="path()"
  [validationErrorText]="validationErrorText()"
>
  <input
    class="input input-bordered w-full"
    type="time"
    [required]="required"
    [ngModel]="value()"
    (ngModelChange)="setValue($event); validateOnChange()"
    (blur)="validateOnBlur()"
    [placeholder]="placeholder"
    data-test="schema-form-widget-input"
  />
</smallstack-form-input-container>
