<smallstack-form [schema]="SCHEMA" [options]="{ useInfoBox: true }" [(value)]="contact">
  <smallstack-schema-form-custom-layout>
    <div class="flex flex-col w-full">
      <div class="section-header">
        <smallstack-icon name="user" size="24" />
        Persönliches</div
      >
      <div class="flex flex-col-reverse gap-10 justify-start place-items-center">
        <div class="flex flex-col gap-3 w-full">
          <ng-container schemaFormPlaceholder="title" />
          <ng-container schemaFormPlaceholder="salutation" />
          <ng-container schemaFormPlaceholder="firstName" />
          <ng-container schemaFormPlaceholder="lastName" />
        </div>
        <div class="flex flex-col gap-1 justify-center place-items-center">
          <smallstack-avatar size="xl" [url]="contact?.avatarUrl" />
          <smallstack-file-explorer-button [fileStore]="fileStore" (selected)="setAvatar($event)" />
        </div>
      </div>
      <ng-container schemaFormPlaceholder="address" />
      <ng-container schemaFormPlaceholder="birthday" />
      <div class="flex flex-row justify-start place-items-center">
        <div class="form-control">
          <label class="label cursor-pointer">
            <input
              type="checkbox"
              class="checkbox checkbox-primary"
              [checked]="contact?.publicAccess"
              (change)="contact.publicAccess = $any($event.srcElement).checked"
            />
            <span class="label-text ml-2 flex flex-row">
              <smallstack-i18n data="@@contacts.publicaccess.label" />
              <smallstack-help-icon helpText="@@contacts.publicaccess.description" />
            </span>
          </label>
        </div>
      </div>
      <div class="section-header"><smallstack-icon name="phone" size="24" /> Erreichbarkeit</div>
      <ng-container schemaFormPlaceholder="email" />
      <ng-container schemaFormPlaceholder="mobile" />
      <ng-container schemaFormPlaceholder="phone" />
      <div class="section-header"><smallstack-icon name="company" size="24" /> Firma</div>
      <ng-container schemaFormPlaceholder="company" />
      <ng-container schemaFormPlaceholder="companyPosition" />
      <ng-container schemaFormPlaceholder="companyPhone" />
      <ng-container schemaFormPlaceholder="companyAddress" />
    </div>
  </smallstack-schema-form-custom-layout>
</smallstack-form>
@if (customFieldSchema?.properties | nonEmptyObject) {
  <div class="section-header">
    <smallstack-icon name="group-background-selected" size="24" />
    <smallstack-i18n data="@@backoffice.contacts.editor.customfields"
  /></div>
  <smallstack-form [schema]="customFieldSchema" [(value)]="customFieldData">
    <smallstack-form-table [showHeader]="false" [showActions]="false" [showMissingFields]="false" />
  </smallstack-form>
}
<ng-template #loading>
  <smallstack-loader />
</ng-template>
