import { ScrollingModule } from "@angular/cdk/scrolling";
import { registerLocaleData } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouterModule } from "@angular/router";
import { TraceService, createErrorHandler } from "@sentry/angular-ivy";
import { addAllWidgets } from "@smallstack/all-widgets";
import { AxiosApiClient, ResellersApi } from "@smallstack/axios-api-client";
import { BackofficeIcons } from "@smallstack/backoffice-shared";
import { DataStore, EnvironmentService, LoadingElementDirective } from "@smallstack/common-components";
import { ContentStageBridge } from "@smallstack/content-stage-shared";
import { SmallstackCrmModule } from "@smallstack/crm-components";
import { SmallstackFormModule, addDefaultFormInputComponents } from "@smallstack/form-components";
import { addDefaultPdfFormInputComponents } from "@smallstack/form-components/pdf";
import { LocaleService, SmallstackI18nModule, TranslationLoader, TranslationStore } from "@smallstack/i18n-components";
import { loadCheckin } from "@smallstack/language-packs";
import { SmallstackQRCodeModule } from "@smallstack/qrcode-components";
import { ListContainerComponent, LoaderComponent, StoreContainerComponent } from "@smallstack/store-components";
import { IconThemeService, SmallstackThemeModule } from "@smallstack/theme-components";
import {
  INPUT_WIDGETS_TRANSLATION_STORE,
  SchemaFormInputsRegistry,
  SmallstackFormCoreModule,
  WidgetRegistry
} from "@smallstack/widget-core";
import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { appRoutes } from "./app.routes";
import { currentReseller$, currentTenantId$ } from "./app.subscriptions";
import { SmallstackComponentsModule } from "./modules/components/components.module";
import { HomeComponent } from "./pages/home/home.component";
import { SimpleDialogComponent } from "./pages/simple-dialog/simple-dialog.component";
import { VisitorAppComponent } from "./pages/visitor-app/visitor-app.component";
import { VisitorFormPageComponent } from "./pages/visitor-form-page/visitor-form-page.component";
import { VisitorLoginComponent } from "./pages/visitor-scan/visitor-login.component";
import { VisitorComponent } from "./pages/visitor/visitor.component";
import { CheckinService } from "./shared/checkin.service";
import { AjvFactory } from "@smallstack/typesystem";
import { TypeService, typesystemClientProviders } from "@smallstack/typesystem-client";

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    VisitorAppComponent,
    HomeComponent,
    VisitorFormPageComponent,
    SimpleDialogComponent,
    VisitorLoginComponent,
    VisitorComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    // ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production })
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, { paramsInheritanceStrategy: "always" }),
    ScrollingModule,
    SmallstackComponentsModule,
    SmallstackFormCoreModule,
    SmallstackFormModule,
    SmallstackI18nModule,
    SmallstackQRCodeModule,
    SmallstackThemeModule,
    SmallstackCrmModule,
    LoadingElementDirective,
    LoaderComponent,
    StoreContainerComponent,
    ListContainerComponent
  ],
  providers: [
    ...typesystemClientProviders,
    {
      provide: LOCALE_ID,
      useValue: "de-DE"
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
        logErrors: true
      })
    },
    {
      provide: AxiosApiClient,
      useFactory: () => {
        const pathSplits = window.location.pathname.split("/");
        let tenantId: string;
        let resellerId: string;
        if (pathSplits.length >= 6) {
          resellerId = pathSplits[1];
          tenantId = pathSplits[3];
        }
        // fix url for reseller detection
        if (resellerId === "morgenstern" && window.location.hostname === "checkin.smallstack.com") {
          // redirect
          window.location.hostname = "checkin.morgenstern.de";
          return;
        }
        // add default reseller
        if (resellerId === undefined) resellerId = "smallstack";
        currentTenantId$.next(tenantId);
        return new AxiosApiClient({
          apiUrl: environment.apiUrl,
          tenantId: () => currentTenantId$.value,
          resellerId: () => resellerId
        });
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (localeService: LocaleService, translationStore: TranslationStore, translationLoader: TranslationLoader) =>
        async () => {
          currentReseller$.next(
            (await new AxiosApiClient({ apiUrl: environment.apiUrl }).get(ResellersApi).getReseller()).data
          );
          if (currentTenantId$.value !== undefined) {
            translationLoader.register([loadCheckin]);
            await localeService.getAllLocales();
            await localeService.detectLocale();
          } else await localeService.setCurrentLocale("de_de");
          translationStore.addValue({ key: "common.dismiss", values: { de_de: "Ok" } });
          translationStore.addValue({ key: "actions.close", values: { de_de: "Schließen" } });
          translationStore.addValue({ key: "actions.dialogdismiss", values: { de_de: "Schließen" } });
          translationStore.addValue({
            key: "errors.validations.required",
            values: {
              de_de: "Pflichtfeld, bitte ausfüllen",
              en_us: "Required field"
            }
          });
        },
      deps: [LocaleService, TranslationStore, TranslationLoader, TraceService],
      multi: true
    },
    {
      provide: TraceService,
      useFactory: (router: Router) => new TraceService(router),
      deps: [Router]
    },
    {
      provide: ContentStageBridge,
      useFactory: () => new ContentStageBridge()
    },
    LocaleService,
    TranslationStore,
    CheckinService,
    DataStore,
    { provide: INPUT_WIDGETS_TRANSLATION_STORE, useExisting: TranslationStore },
    {
      provide: AjvFactory,
      useFactory: (typeService: TypeService) => {
        return new AjvFactory(typeService);
      },
      deps: [TypeService]
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  constructor(
    iconThemeService: IconThemeService,
    schemaFormInputsRegistry: SchemaFormInputsRegistry,
    environmentService: EnvironmentService,
    widgetRegistry: WidgetRegistry
  ) {
    environmentService.set(environment as any);

    addDefaultFormInputComponents(schemaFormInputsRegistry);
    addDefaultPdfFormInputComponents(schemaFormInputsRegistry);
    addAllWidgets(widgetRegistry);

    // disable autofill globally
    document.addEventListener("autocomplete", (e) => {
      e.preventDefault();
    });

    iconThemeService.setAliases(BackofficeIcons);
  }
}
