<div class="info flex flex-col space-y-25 justify-center items-center">
  <div class="flex flex-row space-x-3 justify-center items-center">
    <img class="app-icon" src="assets/icons/appicon-76x76.png" />
    <div class="flex flex-col">
      <h1>Check-in App</h1>
      <h2 data-test="startpage-subtitle">Die App für Ihre Besucher und Ihre Events</h2>
    </div>
  </div>
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="flex flex-col space-y-10 justify-center items-center">
        @if (!isScanning) {
          <div class="outlined pointable flex flex-col" (click)="goScanQrCode()">
            <small>App per QR-Code öffnen</small>
            <smallstack-icon alias="camera" size="24" color="primary" class="scan-icon" />
          </div>
        }
        @if (isScanning) {
          <div class="outlined">
            @if (isScanning) {
              <smallstack-barcode-scanner
                [scanReadyMessage]="scanReadyMessage"
                (scanSuccess)="onScannerSuccess($event)"
                (cancelScan)="goBack()"
              />
            }
          </div>
        }
        @if (storedApp) {
          <div class="flex flex-col space-y-5 justify-center items-center">
            <span>oder</span>
            <button class="btn btn-primary" (click)="loadApp(storedApp)">GESPEICHERTE APP LADEN</button>
          </div>
        }
      </div>
    </mat-card-content>
  </mat-card>
</div>
