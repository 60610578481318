import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";

@Component({
  templateUrl: "./date-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFormInputComponent extends SchemaFormBaseWidget implements OnInit {
  public defaultsToNow: boolean;
  public onlyDateInFuture: boolean;
  public onlyDateInPast: boolean;
  public minDate: Date;
  public maxDate: Date;
  public currentDate: Date = null;

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.schema$.subscribe((schema) => {
        this.defaultsToNow = schema["x-schema-form"].defaultsToNow;
        this.onlyDateInFuture = schema["x-schema-form"].onlyDateInFuture;
        this.onlyDateInPast = schema["x-schema-form"].onlyDateInPast;
        this.cdr.markForCheck();
      })
    );

    this.subscription.add(
      this.value$.subscribe((value: number) => {
        if (value === undefined) this.currentDate = null;
        else this.currentDate = new Date(value);
        this.cdr.markForCheck();
      })
    );
  }

  public dateChanged(changed: MatDatepickerInputEvent<Date>): void {
    this.currentDate = changed.value;
    this.setValue(changed.value.valueOf());
  }

  public deleteDate(): void {
    this.currentDate = null;
    this.setValue(undefined);
  }
}
