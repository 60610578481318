import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { ActivatedRoute, Router } from "@angular/router";
import { PublicContactsStore } from "@smallstack/crm-components";
import { Form } from "@smallstack/form-shared";
import { Store, StoreState } from "@smallstack/store";
import { Observable, Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import { CheckinService } from "../../shared/checkin.service";
import { VisitorApp } from "../../shared/types";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./visitor-app.component.html",
  styleUrls: ["./visitor-app.component.scss"]
})
export class VisitorAppComponent implements OnInit, OnDestroy {
  public appStore: Store<VisitorApp>;
  public forms$: Observable<Form[]>;
  public dismissAlertBox: boolean = false;
  public dontShowThisAgain: boolean = false;
  public version = environment.version;
  public appIdUrl: string;
  public error: string;

  private sub: Subscription = new Subscription();
  private secretSpotTimeout: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private contactsStore: PublicContactsStore,
    checkinService: CheckinService,
    private cdr: ChangeDetectorRef
  ) {
    this.appStore = checkinService.currentApp$;
    this.forms$ = checkinService.currentForms$;
  }

  public async ngOnInit() {
    // wait for contact persons store
    if (this.contactsStore.state === StoreState.INITIAL) await this.contactsStore.preload();

    if (localStorage.getItem("dontShowThisAgain")) {
      this.dontShowThisAgain = JSON.parse(localStorage.getItem("dontShowThisAgain"));
      if (this.dontShowThisAgain) this.dismissAlertBox = true;
    }

    this.appIdUrl = this.router.url.split("/").slice(-1)[0];

    this.appStore.error$.subscribe((error: Error) => (this.error = error?.message));

    this.cdr.markForCheck();
  }

  public async goRegister(form: Form) {
    await this.router.navigate(["./forms", form.id], { relativeTo: this.activatedRoute });
  }

  public async goScanQrCode() {
    await this.router.navigate(["./visitor"], { relativeTo: this.activatedRoute });
  }
  public toggleDontShowThisAgain(event: MatCheckboxChange) {
    this.dontShowThisAgain = event.checked;
    localStorage.setItem("dontShowThisAgain", JSON.stringify(this.dontShowThisAgain));
    this.cdr.markForCheck();
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public mouseDown() {
    this.secretSpotTimeout = setTimeout(async () => {
      await this.router.navigate(["./manage"], { relativeTo: this.activatedRoute });
    }, 3000);
  }

  public mouseUp() {
    clearTimeout(this.secretSpotTimeout);
  }

  public redirectUrl() {
    void this.router.navigate([""]);
  }
}
