@if (options$ | async; as options) {
  @if (schema$ | async; as schema) {
    <smallstack-form-input-container
      [options]="options"
      [schema]="schema"
      [path]="path()"
      [validationErrorText]="validationErrorText()"
    >
      <label class="join">
        <input
          type="number"
          class="input input-bordered w-full join-item"
          [required]="required"
          [ngModel]="displayValue$ | async"
          (ngModelChange)="updateValue($event); validateOnChange()"
          (blur)="validateOnBlur()"
          [placeholder]="placeholder"
          data-test="schema-form-widget-percentage"
          (keyup.enter)="formService.submit()"
          [ngClass]="{ 'input-error': validationErrorText() !== undefined }"
        />
        <span class="join-item">%</span>
      </label>
    </smallstack-form-input-container>
  }
}
