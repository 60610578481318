import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { getSchemaPropertiesKeys } from "@smallstack/utils";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";
import { derivedAsync } from "ngxtension/derived-async";

@Component({
  selector: "smallstack-schema-property-selector-form-input",
  templateUrl: "./schema-property-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchemaPropertySelectorFormInputComponent extends SchemaFormBaseWidget implements OnInit {
  protected values = derivedAsync(async () => {
    const schema = this.schema();
    if (!schema) return undefined;
    const typeName = schema["x-schema-form"]?.typeName;
    if (typeName) {
      const typeNameSchema = this.typeService.getByPath(typeName)?.schema;
      if (typeNameSchema) return getSchemaPropertiesKeys(typeNameSchema);
    }
    if (schema["x-schema-form"]?.typeNameProperty) {
      const typeNameProperty = this.formService.getValueByPath(schema["x-schema-form"].typeNameProperty);
      if (typeNameProperty) {
        const typeNamePropertySchema = this.typeService.getByPath(typeNameProperty)?.schema;
        if (typeNamePropertySchema) return getSchemaPropertiesKeys(typeNamePropertySchema);
      }
    }
  });
}
