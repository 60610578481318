<div class="p-4 flex flex-col space-y-5 items-stretch">
  @if (appStore.value$ | async; as app) {
    <app-header [backNavigate]="backNavigate" [app]="app" />
    @if (form$ | async; as form) {
      <div style="height: 100%; overflow: auto" class="flex flex-row justify-center items-stretch">
        <div class="visitor-card">
          <div id="forms" class="flex flex-col space-y-5 items-start">
            <h2 fxFlexAlign="center" data-test="checkin-app-form-title">
              <smallstack-i18n [data]="form.title" />
            </h2>
            <mat-card
              style="width: 100%"
              class="checkin-form-container flex flex-col space-y-5 justify-center items-stretch p-4"
              data-test="checkin-app-form-container"
            >
              <smallstack-form [schema]="form.fields" [(value)]="formData">
                <smallstack-schema-form-layout [layout]="form.layout" />
              </smallstack-form>
              @if (app.deletionPeriods) {
                @if (app.deletionPeriods.length === 1) {
                  <mat-label
                    ><small>Automatische Bereinigung Ihrer Daten: {{ app.deletionPeriods[0].label }}</small></mat-label
                  >
                }
                @if (app.deletionPeriods.length > 1) {
                  <mat-form-field>
                    <mat-label style="white-space: normal">Wann sollen Ihre Daten gelöscht werden?</mat-label>
                    <mat-select [(ngModel)]="selectedDeletionPeriodDuration">
                      @for (dp of app.deletionPeriods; track dp) {
                        <mat-option [value]="dp.duration"
                          >{{ dp.label }}
                          @if (dp.isDefault) {
                            <span>&nbsp;(standard)</span>
                          }
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                }
              }
              <!-- SUBMIT BUTTON -->
              <button
                data-test="checkin-app-form-submit"
                class="btn btn-primary btn-block btn-lg rounded-lg"
                [loadingFn]="createVisitor()"
              >
                <smallstack-i18n [data]="form.submitButtonText" />
              </button>
            </mat-card>
            @if (app.smallPrint) {
              <span class="mat-caption">{{ app.smallPrint }}</span>
            }
          </div>
        </div>
      </div>
    } @else {
      <div style="height: 100%" class="flex justify-center items-center">
        <mat-spinner diameter="150" color="primary" />
      </div>
    }
  } @else {
    <div style="height: 100%" class="flex justify-center items-center">
      <mat-spinner diameter="150" color="primary" />
    </div>
  }
</div>
<app-footer />
