import { Route } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { SimpleDialogComponent } from "./pages/simple-dialog/simple-dialog.component";
import { VisitorAppComponent } from "./pages/visitor-app/visitor-app.component";
import { VisitorFormPageComponent } from "./pages/visitor-form-page/visitor-form-page.component";
import { VisitorLoginComponent } from "./pages/visitor-scan/visitor-login.component";
import { VisitorComponent } from "./pages/visitor/visitor.component";

const appPath = ":resellerId/tenants/:tenantId/apps/:appId";

export const appRoutes: Route[] = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "error",
    component: SimpleDialogComponent
  },
  {
    path: appPath,
    component: VisitorAppComponent,
    canActivate: []
  },
  {
    path: `${appPath}/manage`,
    loadChildren: () =>
      import("./modules/management/management.module").then(
        (managementModule) => managementModule.SmallstackManagementModule
      )
  },
  {
    path: `${appPath}/success`,
    component: SimpleDialogComponent,
    canActivate: []
  },
  {
    path: `${appPath}/error`,
    component: SimpleDialogComponent,
    canActivate: []
  },
  {
    path: `${appPath}/forms/:formId`,
    component: VisitorFormPageComponent,
    canActivate: []
  },
  {
    path: `${appPath}/visitor`,
    component: VisitorLoginComponent,
    canActivate: []
  },
  {
    path: `${appPath}/visitor/:visitorId`,
    component: VisitorComponent,
    canActivate: []
  }
];
