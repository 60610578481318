import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ContactCustomFieldValueDto } from "@smallstack/axios-api-client";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";
import { first } from "rxjs";
import { ContactCustomFieldStore } from "../../../stores/contact-custom-field.store";

@Component({
  selector: "smallstack-custom-contact-fields-input-widget",
  templateUrl: "./custom-contact-fields-input-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomContactFieldsInputWidgetComponent extends SchemaFormBaseWidget implements OnInit {
  protected customContactFieldValues: ContactCustomFieldValueDto[];

  constructor(protected contactCustomFieldStore: ContactCustomFieldStore) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.value$.pipe(first()).subscribe((value) => (this.customContactFieldValues = value)));
  }

  protected addEntry(): void {
    if (this.customContactFieldValues === undefined) this.customContactFieldValues = [];
    this.customContactFieldValues.push({} as any);
  }

  protected removeEntry(index: number): void {
    if (this.customContactFieldValues === undefined) return;
    this.customContactFieldValues.splice(index, 1);
    this.updateValue();
  }

  protected updateValue(): void {
    if (!this.customContactFieldValues || this.customContactFieldValues.length === 0) this.setValue(undefined);
    else this.setValue(this.customContactFieldValues);
  }
}
