import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { ContentStageBridge } from "@smallstack/content-stage-shared";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";

const ScanWaitMessage = "Einen Augenblick bitte...";
const ScanRequestPermission = "Problem bei Zugriff auf Kamera";
const ScanRequestPermissionBody =
  "Sie haben den Kamerazugriff explizit verboten. Um dieses Feature nutzen zu können, gewähren Sie der App Zugriff auf die Kamera";
const ScanCamerasNotFoundMessage = "Es konnte keine Kamera gefunden werden!";

@Component({
  selector: "smallstack-barcode-scanner",
  templateUrl: "./barcode-scanner.component.html",
  styleUrls: ["./barcode-scanner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {
  public isSearchingCamera: boolean = true;
  public isScanningNatively: boolean = false;
  public messageTitle = ScanWaitMessage;
  public messageBody: string;
  public nativeScannerAvailable: boolean;
  public nativeScannerErrorMessage: string;

  @Input()
  public scanReadyMessage: string;

  @Output()
  public scanSuccess: EventEmitter<string> = new EventEmitter();

  @Output()
  public cancelScan: EventEmitter<boolean> = new EventEmitter();

  @ViewChild("scannerComponent", { static: false })
  public scanner: ZXingScannerComponent;

  public scanPermission: boolean = true;
  public currentCamera: MediaDeviceInfo = null;
  public cameras: MediaDeviceInfo[];

  constructor(
    private stageBridge: ContentStageBridge,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.nativeScannerAvailable = this.stageBridge.isAvailable();
    if (this.nativeScannerAvailable) {
      void this.doNativeScan();
    }
  }

  public ngOnDestroy(): void {
    if (this.scanner) this.scanner.scanStop();
  }

  public async doNativeScan() {
    try {
      this.isScanningNatively = true;
      this.cdr.markForCheck();
      this.nativeScannerErrorMessage = undefined;
      const text: string = await this.stageBridge.scanBarcode();
      this.scanSuccess.emit(text);
    } catch (e) {
      this.nativeScannerErrorMessage = e;
    }
    this.isScanningNatively = false;
    this.cdr.markForCheck();
  }

  public onScannerPermissionResponse(event?: boolean) {
    this.scanPermission = event;
    if (!event) {
      this.messageTitle = ScanRequestPermission;
      this.messageBody = ScanRequestPermissionBody;
      this.isSearchingCamera = false;
    }
    this.cdr.markForCheck();
  }

  public async onScannerCamerasFound(event?: MediaDeviceInfo[]) {
    this.cameras = event;

    const savedDeviceId = localStorage.getItem("currentCamera");
    if (savedDeviceId) {
      const savedDevice = this.cameras.find((c) => c.deviceId === savedDeviceId);
      if (savedDevice) this.currentCamera = savedDevice;
    }
    if (!this.currentCamera) {
      let found = this.cameras.find((c) => c.label.toLowerCase().indexOf("front") !== -1);
      if (!found) {
        found = this.cameras.find((c) => c.label.toLowerCase().indexOf("camera") !== -1);
      }
      if (!found) {
        found = this.scanner.device;
      }
      if (!found) {
        found = this.cameras[0];
      }
      this.currentCamera = found;
    }
    this.messageTitle = this.scanReadyMessage;
    this.isSearchingCamera = false;
    this.scanner.restart();
    this.cdr.markForCheck();
  }

  public onScannerCamerasNotFound() {
    this.isSearchingCamera = false;
    this.messageTitle = ScanCamerasNotFoundMessage;
    this.cdr.markForCheck();
  }

  public changeCamera() {
    this.scanner.scanStop();
    let index = this.cameras.findIndex((c) => this.currentCamera && c.deviceId === this.currentCamera.deviceId) + 1;
    if (index === this.cameras.length) {
      index = 0;
    }
    this.currentCamera = this.cameras[index];
    localStorage.setItem("currentCamera", this.currentCamera.deviceId);
    this.scanner.restart();
  }

  public goBack() {
    this.cancelScan.emit();
  }

  public async onScannerSuccess(event: string) {
    this.scanSuccess.emit(event);
  }

  public async onScannerError(msg: string, event: any) {
    console.error({ msg, event });
  }
}
