import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, CRMApi, CorporationDto } from "@smallstack/axios-api-client";
import { TypeDto } from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";
import { CorporationType, TypeSupport, toTypeDto } from "@smallstack/typesystem";

@Injectable({ providedIn: "root" })
export class CorporationStore extends PageableCrudStore<CorporationDto> implements TypeSupport {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
    this.query$.next({ page: 1, size: 100, sort: "name" });
  }

  public async getType(): Promise<TypeDto> {
    return toTypeDto(CorporationType);
  }

  protected async createModel(corporation: CorporationDto): Promise<CorporationDto> {
    const res = await this.axiosApiClient.get(CRMApi).createCorporation({ corporation });
    return res.data;
  }
  protected async patchModel(id: string, corporation: Partial<CorporationDto>): Promise<CorporationDto> {
    const res = await this.axiosApiClient.get(CRMApi).patchCorporation({ corporation, id });
    return res.data;
  }
  protected async putModel(corporation: CorporationDto): Promise<CorporationDto> {
    const res = await this.axiosApiClient.get(CRMApi).putCorporation({ corporation, id: corporation.id });
    return res.data;
  }
  protected async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(CRMApi).deleteCorporation({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(CRMApi).deleteManyCorporations({ ids });
    return res.data;
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<CorporationDto>> {
    const res = await this.axiosApiClient.get(CRMApi).getCorporations(query);
    return res.data;
  }
  protected async loadModelById(id: string): Promise<CorporationDto> {
    const res = await this.axiosApiClient.get(CRMApi).getCorporation({ id });
    return res.data;
  }
}
