import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { ReactiveFormBasedInputWidgetComponent } from "@smallstack/widget-core";
import { LinkViewDialogComponent } from "./link-view-dialog/link-view-dialog.component";

@Component({
  selector: "smallstack-document-form-input",
  templateUrl: "./document-form-input.component.html",
  styleUrls: ["./document-form-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentFormInputComponent extends ReactiveFormBasedInputWidgetComponent {
  public checked: boolean = false;
  public fileUrl = computed(() => {
    return this.schema()?.["x-schema-form"]?.fileUrl;
  });

  constructor(private dialog: MatDialog) {
    super();
  }

  public openFilePreview(): void {
    this.dialog
      .open(LinkViewDialogComponent, {
        data: { src: this.fileUrl(), title: this.label },
        autoFocus: false,
        height: "90vh",
        width: "90vh",
        minWidth: "90vh",
        maxHeight: "90vh",
        minHeight: "90vh"
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const { accepted } = result;
          this.checked = accepted;
          this.formControl.setValue(this.checked === true ? true : undefined);
          if (accepted) this.setValue(accepted);
          else this.setValue(undefined);
          this.cdr.markForCheck();
        }
      });
  }

  public markValue($event: MatCheckboxChange): void {
    if ($event.checked) this.setValue($event.checked);
    else this.setValue(undefined);
    this.checked = $event.checked;
    this.formControl.setValue(this.checked === true ? true : undefined);
  }
}
