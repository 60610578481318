<div class="compact-form-field-row flex flex-row space-x-1 h-full w-full min-h-full min-w-full">
  <mat-form-field
    [appearance]="options()?.appearance"
    attr.data-test="schema-form-widget-input-{{ path() }}"
    class="flex-auto"
  >
    @if (options()?.showLabel !== false) {
      <mat-label>
        <smallstack-form-field-title [path]="path()"></smallstack-form-field-title>
      </mat-label>
    }
    <input
      matInput
      [required]="required"
      [ngModel]="model()"
      (ngModelChange)="search($event)"
      [errorStateMatcher]="errorStateMatcher"
      data-test="schema-form-widget-autocomplete"
      [matAutocomplete]="auto"
      placeholder="Fangen Sie an zu tippen..."
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelected($event)"
      [displayWith]="displayFn.bind(this)"
    >
      @for (option of filteredOptions(); track option) {
        <mat-option [value]="option">
          <smallstack-i18n [data]="option?.label"></smallstack-i18n>
        </mat-option>
      }
    </mat-autocomplete>
    @if (schema()?.description && options()?.showHint !== false && options()?.useInfoBox !== true) {
      <mat-hint>{{ schema()?.description }}</mat-hint>
    }
    <mat-error data-test="validation-error-text" class="justify-end">{{ validationErrorText() }} </mat-error>
    @if (schema()?.description && options()?.showHint !== false && options()?.useInfoBox === true) {
      <div matSuffix>
        <smallstack-icon
          name="question-mark"
          theme="ios-glyphs"
          color="AAAAAA"
          size="16"
          [matTooltip]="schema()?.description"
          style="cursor: help; padding-bottom: 8px"
        ></smallstack-icon>
      </div>
    }
  </mat-form-field>
  @if (hasEditorComponent) {
    <button class="btn-action" [loadingFn]="openEditor()">
      <smallstack-icon name="add" size="24"></smallstack-icon>
    </button>
  }
</div>
