import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CheckinVisitorDto, CheckinVisitorEventDto } from "@smallstack/axios-api-client";
import { Store } from "@smallstack/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CheckinService } from "../../shared/checkin.service";
import { ScanCodeNotRecognized } from "../../shared/messages";
import { SimpleDialogPageState, VisitorApp, CheckinAppEvent } from "../../shared/types";

@Component({
  selector: "app-visitor",
  templateUrl: "./visitor.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitorComponent implements OnInit {
  public readonly backNavigate: string = "../";

  public visitor: CheckinVisitorDto;
  public visitorEvent: CheckinVisitorEventDto;
  public appStore: Store<VisitorApp>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private checkinService: CheckinService,
    private cdr: ChangeDetectorRef
  ) {
    this.visitor = this.router.getCurrentNavigation().extras.state as CheckinVisitorDto;
    this.appStore = checkinService.currentApp$;
  }

  public async ngOnInit(): Promise<void> {
    this.visitor = await this.checkinService.getVisitor(this.activatedRoute.snapshot.params.visitorId, {
      when: (error) => error.statusCode === 404,
      dialog: {
        title: "",
        message: ScanCodeNotRecognized,
        okNavigate: this.backNavigate
      }
    });
    this.visitorEvent = this.visitor.latestEvent;
    this.cdr.markForCheck();
  }

  public showEventButton(eventName: string): boolean {
    return this.visitorEvent?.type !== eventName;
  }

  public getEventLabel(name: string): Observable<string> {
    return this.appStore.value$.pipe(
      map((app: VisitorApp) => {
        if (!app.events) return undefined;
        return app.events.find((event) => event.name === name)?.label;
      })
    );
  }

  public getApplicableEvents(): Observable<CheckinAppEvent[]> {
    return this.appStore.value$.pipe(
      map((app: VisitorApp) => {
        if (!app.events) return undefined;
        const publicEvents = app.events.filter((event) => event.public);
        if (!this.visitorEvent) return publicEvents;
        const filteredEvents = publicEvents.filter((event) => event.name !== this.visitorEvent.type);
        if (filteredEvents.length === 0) return undefined;
        return filteredEvents;
      })
    );
  }

  public async changeActiveStatus(event: string, print: boolean): Promise<boolean> {
    await this.checkinService.createVisitEvent(this.visitor.id, event as any);
    // if (print)
    //   await this.checkinService.printVisitorPass(this.visitor, evt);

    let state: SimpleDialogPageState = {} as any;
    if (event === "in")
      state = {
        title: this.appStore.value?.successSignInTitle,
        message: this.appStore.value?.successSignInMessage
      };
    else if (event === "out")
      state = {
        title: this.appStore.value?.successSignOutTitle,
        message: this.appStore.value?.successSignOutMessage
      };
    else
      state = {
        title: "Gespeichert",
        message: "Aktion erfolgreich ausgeführt."
      };
    return this.router.navigate(["../../success"], { relativeTo: this.activatedRoute, state });
  }
}
