import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, ContactDto, ContactsApi } from "@smallstack/axios-api-client";
import { PageableStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class PublicContactsStore extends PageableStore<ContactDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
    this.query$.next({ page: 1, size: 1000, sort: "lastName" });
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<ContactDto>> {
    return (await this.axiosApiClient.get(ContactsApi).getPublicContacts(query)).data;
  }
}
