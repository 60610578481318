import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ScanReadyMessage } from "../../shared/messages";

@Component({
  selector: "app-visitor-scan",
  templateUrl: "./visitor-login.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitorLoginComponent {
  public readonly backNavigate: string = "../";

  public scanReadyMessage = ScanReadyMessage;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public async onScannerSuccess(event: string) {
    await this.router.navigate([event], { relativeTo: this.activatedRoute });
  }
}
