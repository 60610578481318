import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SimpleDialogPageState } from "../../shared/types";

@Component({
  templateUrl: "./simple-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleDialogComponent implements OnInit, OnDestroy {
  public state: SimpleDialogPageState;

  private timeout: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.state = this.router.getCurrentNavigation().extras.state as SimpleDialogPageState;
    if (!this.state) {
      void this.goBack();
    } else if (!this.state.icon) {
      const route = this.activatedRoute.snapshot.url.join().split(",");
      switch (route.reverse()[0]) {
        case "success":
          this.state.icon = "icon-checked";
          break;
        case "error":
          this.state.icon = "icon-error";
          break;
      }
    }
  }

  public ngOnInit(): void {
    this.timeout = setTimeout(
      () => {
        void this.goBack();
      },
      this.state && this.state.timeout ? this.state.timeout : 10000
    );
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }

  public goBack(): Promise<boolean> {
    const route = !this.state || !this.state.okNavigate ? "../" : this.state.okNavigate;
    return this.router.navigate([route], { relativeTo: this.activatedRoute });
  }
}
