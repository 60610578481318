@if (options$ | async; as options) {
  @if (schema$ | async; as schema) {
    <mat-form-field
      [appearance]="options?.appearance"
      attr.data-test="schema-form-widget-date-{{ path() }}"
      class="h-full w-full min-h-full min-w-full"
    >
      @if (options?.showLabel !== false) {
        <mat-label>
          <smallstack-form-field-title [path]="path()"></smallstack-form-field-title>
        </mat-label>
      }
      <input
        matInput
        [matDatepicker]="basicDatepicker"
        (dateChange)="dateChanged($event); validateOnChange()"
        (dateInput)="dateChanged($event); validateOnChange()"
        [value]="currentDate"
        readonly
        (click)="basicDatepicker.open()"
        [max]="maxDate"
        [min]="minDate"
        data-test="date-editor-input"
        [errorStateMatcher]="errorStateMatcher"
        [placeholder]="placeholder"
      />
      <smallstack-icon
        mat-icon-button
        alias="calendar"
        size="24"
        style="cursor: pointer; margin-right: 5px"
        (click)="basicDatepicker.open()"
        matPrefix
      ></smallstack-icon>
      <mat-datepicker #basicDatepicker startView="multi-year"></mat-datepicker>
      @if (currentDate) {
        <smallstack-icon
          mat-icon-button
          alias="delete"
          size="24"
          style="cursor: pointer"
          (click)="deleteDate()"
          matSuffix
        ></smallstack-icon>
      }
      @if (schema.description && options?.showHint !== false && options?.useInfoBox !== true) {
        <mat-hint>{{ schema.description }}</mat-hint>
      }
      <mat-error class="justify-end">{{ validationErrorText() }} </mat-error>
      @if (schema.description && options?.showHint !== false && options?.useInfoBox === true) {
        <div matSuffix>
          <smallstack-icon
            name="question-mark"
            theme="ios-glyphs"
            color="AAAAAA"
            size="16"
            [matTooltip]="schema.description"
            style="cursor: help; padding-bottom: 4px"
          ></smallstack-icon>
        </div>
      }
    </mat-form-field>
  }
}
