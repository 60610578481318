import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationService } from "@smallstack/i18n-components";

const LOCAL_STORAGE_SAVED_APP_KEY = "storedApp";

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {
  private static saveStoredApp(url: string): void {
    localStorage.setItem(LOCAL_STORAGE_SAVED_APP_KEY, url);
  }

  private static getStoredApp(): string {
    const storedApp: string = localStorage.getItem(LOCAL_STORAGE_SAVED_APP_KEY);
    return storedApp === null ? undefined : storedApp;
  }

  public isScanning = false;
  public scanReadyMessage = "Bitte halten Sie den QR-Code vor die Kamera, den Sie von Ihrem Reseller bekommen haben";
  public storedApp: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.storedApp = HomeComponent.getStoredApp();
  }

  public goScanQrCode() {
    this.isScanning = true;
  }

  public goBack() {
    this.isScanning = false;
  }

  public onScannerSuccess(url: string | any) {
    try {
      url = JSON.parse(url);
    } catch (e) {
      //
    }
    if (typeof url === "object") {
      if (typeof url?.content?.data === "string" && url.content.data.startsWith("http")) url = url.content.data;
      else
        return this.notificationService.popup.error(
          "Ungültiger QR Code",
          "Der Inhalt des QR Codes wurde nicht erkannt. Bitte scannen Sie nur Check-In App kompatible QR Codes!"
        );
    }
    if (url !== undefined) {
      url = new URL(url).pathname;
      HomeComponent.saveStoredApp(url);
      this.loadApp(url);
    }
  }

  public loadApp(appPath: string) {
    void this.router.navigateByUrl(appPath);
  }
}
