import { Injectable } from "@angular/core";
import { AxiosApiClient, ContactCustomFieldDto, ContactCustomFieldsApi } from "@smallstack/axios-api-client";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class ContactCustomFieldStore extends PageableCrudStore<ContactCustomFieldDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<ContactCustomFieldDto>> {
    return (await this.axiosApiClient.get(ContactCustomFieldsApi).getContactCustomFields(query)).data;
  }

  protected async loadModelById(id: string): Promise<ContactCustomFieldDto> {
    return (await this.axiosApiClient.get(ContactCustomFieldsApi).getContactCustomField({ id })).data;
  }

  protected async deleteModelById(id: string): Promise<void> {
    return (await this.axiosApiClient.get(ContactCustomFieldsApi).deleteContactCustomField({ id })).data;
  }

  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(ContactCustomFieldsApi).deleteManyContactCustomFields({ ids });
    return res.data;
  }

  protected async createModel(contactCustomField: ContactCustomFieldDto): Promise<ContactCustomFieldDto> {
    return (await this.axiosApiClient.get(ContactCustomFieldsApi).createContactCustomField({ contactCustomField }))
      .data;
  }

  protected async patchModel(
    id: string,
    contactCustomField: Partial<ContactCustomFieldDto>
  ): Promise<ContactCustomFieldDto> {
    return (await this.axiosApiClient.get(ContactCustomFieldsApi).patchContactCustomField({ id, contactCustomField }))
      .data;
  }

  protected async putModel(contactCustomField: ContactCustomFieldDto): Promise<ContactCustomFieldDto> {
    return (
      await this.axiosApiClient
        .get(ContactCustomFieldsApi)
        .putContactCustomField({ id: contactCustomField.id, contactCustomField })
    ).data;
  }
}
