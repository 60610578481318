import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { LoadingElementDirective, StoreRegistry } from "@smallstack/common-components";
import { SmallstackFormModule } from "@smallstack/form-components";
import { SchemaFormInputsRegistry, SmallstackFormCoreModule } from "@smallstack/widget-core";
import { SmallstackI18nModule } from "@smallstack/i18n-components";
import { SmallstackTextModule } from "@smallstack/text-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { TYPE_CONTACTS, TYPE_CORPORATIONS } from "@smallstack/typesystem";
import { SmallstackUserModule } from "@smallstack/user-components";
import { WidgetRegistry } from "@smallstack/widget-core";
import { NoteCreationComponent } from "./components/note-creation/note-creation.component";
import { NoteComponent } from "./components/note/note.component";
import { ContactEditorWidgetComponent } from "./components/widgets/contact-editor-widget/contact-editor-widget.component";
import { ContactPersonFormInputComponent } from "./components/widgets/contact-person-form-input/contact-person-form-input.component";
import { ContactPersonSelectComponent } from "./components/widgets/contact-person-form-input/contact-person-select/contact-person-select.component";
import { CustomContactFieldsInputWidgetComponent } from "./components/widgets/custom-contact-fields-input-widget/custom-contact-fields-input-widget.component";
import { ContactsStore } from "./stores/contacts.store";
import { CorporationStore } from "./stores/corporations.store";
import { StoreContainerComponent, StoreModelComponent } from "@smallstack/store-components";

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    ScrollingModule,
    FormsModule,
    SmallstackThemeModule,
    SmallstackI18nModule,
    SmallstackFormModule,
    SmallstackFormCoreModule,
    SmallstackTextModule,
    SmallstackUserModule,
    LoadingElementDirective,
    StoreModelComponent,
    StoreContainerComponent
  ],
  declarations: [
    CustomContactFieldsInputWidgetComponent,
    NoteCreationComponent,
    NoteComponent,
    ContactPersonFormInputComponent,
    ContactPersonSelectComponent
  ],
  exports: [
    CustomContactFieldsInputWidgetComponent,
    NoteCreationComponent,
    NoteComponent,
    ContactPersonFormInputComponent,
    ContactPersonSelectComponent
  ]
})
export class SmallstackCrmModule {
  constructor(
    schemaFormInputsRegistry: SchemaFormInputsRegistry,
    storeRegistry: StoreRegistry,
    contactsStore: ContactsStore,
    corporationStore: CorporationStore,
    widgetRegistry: WidgetRegistry
  ) {
    // register stores
    storeRegistry.registerStore(TYPE_CONTACTS, contactsStore);
    storeRegistry.registerStore(TYPE_CORPORATIONS, corporationStore);

    // register input widgets
    schemaFormInputsRegistry.addWidget("CustomContactFields", CustomContactFieldsInputWidgetComponent);
    schemaFormInputsRegistry.addWidget("contactperson", ContactPersonFormInputComponent);

    // register widgets
    widgetRegistry.registerWidget(ContactEditorWidgetComponent);
  }
}
