<div class="p-4 flex flex-col justify-between items-stretch">
  <app-header [backNavigate]="backNavigate" />
  @if (visitor && appStore.value$ | async; as app) {
    <div class="flex flex-row justify-center items-stretch">
      <div class="visitor-card flex flex-col space-y-5 justify-center items-stretch">
        <div class="flex flex-row justify-between items-center">
          <h2>{{ app.signupTitle }} {{ visitor.fields.firstName }} {{ visitor.fields.lastName }}</h2>
          @if (visitorEvent) {
            <div style="font-size: smaller"
              >Ihre letzte Aktion: "{{ getEventLabel(visitorEvent.type) | async }}" am
              <smallstack-date format="medium" [timestamp]="visitorEvent.createdAt" />
            </div>
          }
        </div>
        <!-- legacy apps -->
        @if (app.events === undefined) {
          @if (showEventButton("in")) {
            <button class="big-checkin-button btn btn-outline" (click)="changeActiveStatus('in', false)"
              >Anmelden
            </button>
          }
          @if (showEventButton("out")) {
            <button class="big-checkin-button btn btn-outline" (click)="changeActiveStatus('out', false)"
              >Abmelden
            </button>
          }
        }
        @if (getApplicableEvents() | async; as events) {
          @for (event of events; track event) {
            @if (showEventButton(event.name)) {
              <button class="big-checkin-button btn btn-outline" (click)="changeActiveStatus(event.name, false)">{{
                event.label
              }}</button>
            }
          }
        } @else {
          <div class="justify-center">
            <small><i>Derzeit stehen für Sie keine Aktionen verfügbar.</i></small>
          </div>
        }
      </div>
    </div>
  }
  <app-footer />
</div>
