<div class="p-4 flex flex-col justify-between items-stretch">
  <app-header />

  @if (state) {
    <div style="height: 100%; overflow: auto" class="flex flex-row justify-center items-stretch">
      <div class="visitor-card">
        <div id="success" style="height: 100%" class="flex flex-col justify-center items-center">
          <h1>{{ state.title }}</h1>
          <i class="dialog-icon {{ state.icon }}"></i>
          <p style="margin-top: 0">{{ state.message }}</p>
          <button
            style="margin-top: 20px"
            data-test="dialog-btn"
            class="btn btn-primary btn-lg rounded-lg"
            (click)="goBack()"
            >Okay</button
          >
        </div>
      </div>
    </div>
  }
</div>
