import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";
import { distinctUntilChangedObj, flattenJson } from "@smallstack/utils";
import { AutoCompleteFormInputComponent } from "../auto-complete-form-input/auto-complete-form-input.component";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./context-variable-input.component.html"
})
export class ContextVariableInputComponent extends SchemaFormBaseWidget implements AfterViewInit {
  @ViewChild(AutoCompleteFormInputComponent)
  private autoComplete: AutoCompleteFormInputComponent;

  public ngAfterViewInit(): void {
    this.autoComplete.prefixIcon = "variable";
    this.subscription.add(
      this.formService.globalFormOptions$.pipe(distinctUntilChangedObj()).subscribe((globalFormOptions) => {
        if (globalFormOptions.context)
          this.autoComplete.values$.next(Object.keys(flattenJson(globalFormOptions.context)));
      })
    );
  }
}
