import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ReactiveFormBasedInputWidgetComponent } from "@smallstack/widget-core";
import { map, Observable } from "rxjs";

@Component({
  selector: "smallstack-price-form-input.component",
  templateUrl: "./price-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceFormInputComponent extends ReactiveFormBasedInputWidgetComponent implements OnInit {
  protected displayValue$: Observable<string>;

  public override ngOnInit(): void {
    super.ngOnInit();
    this.displayValue$ = this.value$.pipe(map((val) => "" + val / 100));
  }

  protected updateValue(val: number): void {
    if (val) {
      let num: string = val.toFixed(2);
      num = num.replace(",", "").replace(".", "");
      this.setValue(parseInt(num));
    } else this.setValue(undefined);
    void this.validateOnChange();
  }
}
