<div class="p-4 flex flex-col justify-between items-stretch">
  <app-header [app]="appStore.value$ | async" />
  <smallstack-store-container [store]="appStore" [showErrorIcon]="false" [showErrorText]="false">
    <div style="height: 100%; overflow: auto; padding-bottom: 50px" class="flex flex-row justify-center items-stretch">
      @if (appStore.value$ | async; as app) {
        @if (forms$ | async; as forms) {
          <div class="visitor-card" style="display: flex; justify-content: center">
            <div id="intro" class="flex flex-col items-stretch" [style.place-content]="app.contentAlignment">
              <h1 data-test="checkin-app-title">{{ app.title }}</h1>
              <p>{{ app.message }}</p>
              @if (app.scannerActive) {
                <div>
                  <p class="mat-caption">{{ app.scanButtonHint }}</p>
                  <button style="width: 100%" class="btn btn-outline" (click)="goScanQrCode()">{{
                    app.scanButtonText
                  }}</button>
                </div>
              }
              @if (forms.length > 0) {
                <p class="mat-caption" style="margin-top: 20px"> {{ app.formButtonHint }}</p>
              }
              <div style="margin-top: 40px" class="flex flex-col space-y-10 items-stretch">
                @for (form of forms; track form) {
                  <button
                    data-test="checkin-app-form-button"
                    class="btn btn-primary btn-block btn-lg rounded-lg"
                    (click)="goRegister(form)"
                  >
                    <smallstack-i18n [data]="form.title" />
                  </button>
                }
                @if (forms.length === 0 && dismissAlertBox === false) {
                  <mat-card>
                    <mat-card-header>
                      <smallstack-icon mat-card-avatar alias="warning" size="24" color="warn" />
                      <mat-card-title>Keine Formulare gefunden</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <p style="text-align: left"
                        >Bitte verknüpfen Sie mindestens ein Formular mit der App. Pro Formular wird ein Button
                        angezeigt.</p
                      >
                    </mat-card-content>
                    <mat-card-actions style="padding: 8px" class="flex flex-row justify-between items-center">
                      <mat-checkbox [checked]="dontShowThisAgain" (change)="toggleDontShowThisAgain($event)"
                        ><span style="font-size: 13px">In Zukunft nicht mehr anzeigen</span></mat-checkbox
                      >
                      <button class="btn btn-primary" (click)="dismissAlertBox = true">Okay</button>
                    </mat-card-actions>
                  </mat-card>
                }
              </div>
            </div>
          </div>
        } @else {
          <div class="flex flex-col space-y-13 justify-center items-center h-full w-full min-h-full min-w-full">
            <mat-spinner diameter="100" color="primary" />
            <smallstack-i18n data="@@loading.form" />
          </div>
        }
      }
    </div>
  </smallstack-store-container>
  @if (error) {
    <div class="fullscreen-layout-container" align="center">
      <div class="message failure" data-test="error-message">
        <h3>Fehler</h3>
        <span>{{ error }}</span>
        <br />
        <button class="btn btn-outline" (click)="redirectUrl()"> Zurück zur Startseite </button>
      </div>
    </div>
  }
  <app-footer />
</div>
<div
  class="management-activation-area"
  (mousedown)="mouseDown()"
  (mouseup)="mouseUp()"
  (pointerdown)="mouseDown()"
  (pointerup)="mouseUp()"
>
  <span>v{{ version }}</span>
</div>
