<div class="dialog h-full w-full">
  <div class="dialog-title">
    <smallstack-i18n [data]="title"></smallstack-i18n>
  </div>
  <div class="dialog-content">
    @if (error) {
      <div class="error">
        Beim Anzeigen des PDF ist ein Fehler aufgetreten:
        <div>{{ error }}</div>
      </div>
    }
    <smallstack-pdf-viewer [src]="data.src" [showToolbar]="false"></smallstack-pdf-viewer>
  </div>
  <div class="dialog-actions">
    <button (click)="reject()" class="btn btn-warning btn-outline">Nicht akzeptieren</button>
    <button (click)="accept()" class="btn btn-primary btn-outline">Akzeptieren</button>
  </div>
</div>
