import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ContentStageBridge } from "@smallstack/content-stage-shared";
import { SmallstackI18nModule } from "@smallstack/i18n-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { BarcodeScannerComponent } from "./barcode-scanner/barcode-scanner.component";
import { QRCodeDialogComponent } from "./qr-code-dialog/qr-code-dialog.component";

export function contentStageBridgeFactory(): ContentStageBridge {
  return new ContentStageBridge();
}

@NgModule({
  imports: [
    CommonModule,
    ZXingScannerModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    SmallstackI18nModule,
    MatDialogModule,
    SmallstackThemeModule
  ],
  declarations: [QRCodeDialogComponent, BarcodeScannerComponent],
  exports: [QRCodeDialogComponent, BarcodeScannerComponent],
  providers: [
    {
      provide: ContentStageBridge,
      useFactory: contentStageBridgeFactory
    }
  ]
})
export class SmallstackQRCodeModule {}
