import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { StoreRegistry } from "@smallstack/common-components";
import { StringSelectFormInputComponent } from "../stringselect-form-input/stringselect-form-input.component";

@Component({
  templateUrl: "./stores-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoresFormInputComponent extends StringSelectFormInputComponent implements OnInit {
  protected storeNames: string[];

  constructor(private storeRegistry: StoreRegistry) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.schema$.subscribe((schema) => {
        this.storeNames = Object.keys(this.storeRegistry.getAllStores());
        this.cdr.markForCheck();
      })
    );
  }
}
