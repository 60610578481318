<div class="flex flex-row items-center w-full">
  <mat-checkbox
    value="false"
    (change)="markValue($event)"
    [checked]="checked"
    [ngClass]="{ error: hasErrors() }"
    [required]="required"
    [formControl]="formControl"
    attr.data-test="checkbox-form-input-toggle-{{ path() }}"
  >
    <mat-label style="font-size: 1.2rem; hyphens: auto">
      <smallstack-i18n [data]="label"></smallstack-i18n>
      @if (required) {
        <ng-container class="mat-placeholder-required mat-form-field-required-marker mat-caption"> *</ng-container>
      }
    </mat-label>
  </mat-checkbox>
  <button class="btn btn-action btn-primary ml-5" (click)="openFilePreview(); $event.preventDefault()">
    <smallstack-icon alias="openinbrowser" size="24"></smallstack-icon>
  </button>
  <mat-error>
    <smallstack-form-control-error [control]="formControl"></smallstack-form-control-error>
  </mat-error>
</div>
<smallstack-form-control-dummy [control]="formControl"></smallstack-form-control-dummy>
