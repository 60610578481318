@if (options$ | async; as options) {
  @if (schema$ | async; as schema) {
    <mat-form-field
      [appearance]="options?.appearance"
      attr.data-test="schema-form-widget-input-{{ path() }}"
      class="h-full w-full min-h-full min-w-full"
    >
      @if (options?.showLabel !== false) {
        <mat-label>
          <smallstack-form-field-title [path]="path()"></smallstack-form-field-title>
        </mat-label>
      }
      <input
        matInput
        currencyMask
        [required]="required"
        [ngModel]="displayValue$ | async"
        (ngModelChange)="updateValue($event)"
        (blur)="validateOnBlur()"
        [errorStateMatcher]="errorStateMatcher"
        [placeholder]="placeholder"
        data-test="schema-form-widget-input"
        [options]="{ prefix: '', suffix: ' €', thousands: '.', decimal: ',', allowNegative: false, align: 'left' }"
      />
      @if (schema.description && options?.showHint !== false && options?.useInfoBox !== true) {
        <mat-hint>{{ schema.description }}</mat-hint>
      }
      <mat-error data-test="validation-error-text" class="justify-end">{{ validationErrorText() }} </mat-error>
      @if (schema.description && options?.showHint !== false && options?.useInfoBox === true) {
        <div matSuffix>
          <smallstack-icon
            name="question-mark"
            theme="ios-glyphs"
            color="AAAAAA"
            size="16"
            [matTooltip]="schema.description"
            style="cursor: help; padding-bottom: 4px"
          ></smallstack-icon>
        </div>
      }
    </mat-form-field>
  }
}
