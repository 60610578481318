import "zone.js";

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { captureException, init, routingInstrumentation } from "@sentry/angular-ivy";
import { Integrations } from "@sentry/tracing";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
  init({
    dsn: environment.sentryDns,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          "localhost",
          "https://sandbox-api.smallstack.com",
          "https://api.smallstack.com",
          "https://api-sandbox.smallstack.com"
        ],
        routingInstrumentation
      })
    ],
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    captureException(err);
    console.error(err);
  });
