@if (options$ | async; as options) {
  <smallstack-store-container [store]="contactCustomFieldStore">
    <div class="mat-outline">
      @if (options?.showLabel !== false) {
        <mat-label>Benutzerdefinierte Felder</mat-label>
      }
      <div style="padding-top: 2px" class="flex flex-row flex-wrap items-center">
        @for (customContactFieldValue of customContactFieldValues; track customContactFieldValue; let index = $index) {
          <div class="flex flex-row items-center">
            @if (!customContactFieldValue.customFieldId) {
              <mat-form-field>
                <mat-label>Feld auswählen</mat-label>
                <mat-select [(ngModel)]="customContactFieldValue.customFieldId" (ngModelChange)="updateValue()">
                  @for (customContactField of contactCustomFieldStore.value$ | async; track customContactField) {
                    <mat-option [value]="customContactField.id">
                      <smallstack-i18n [data]="customContactField.title" />
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }
            @if (customContactFieldValue.customFieldId) {
              <smallstack-store-model
                [store]="contactCustomFieldStore"
                [modelId]="customContactFieldValue.customFieldId"
              >
                <ng-template let-model="model">
                  @switch (model.type) {
                    @case ("boolean") {
                      <mat-checkbox
                        style="padding: 3px"
                        [checked]="customContactFieldValue.value === 'true'"
                        (change)="customContactFieldValue.value = '' + $event.checked; updateValue()"
                        ><smallstack-i18n [data]="model.title"
                      /></mat-checkbox>
                    }
                    @case ("date") {
                      <mat-form-field>
                        <mat-label>
                          <smallstack-i18n [data]="model.title" />
                        </mat-label>
                        <input
                          matInput
                          type="date"
                          [(ngModel)]="customContactFieldValue.value"
                          (ngModelChange)="updateValue()"
                        />
                      </mat-form-field>
                    }
                    @case ("datestring") {
                      <smallstack-datestring-editor
                        [timestamp]="customContactFieldValue.value"
                        (timestampChange)="customContactFieldValue.value = '' + $event"
                      />
                    }
                    @default {
                      <mat-form-field>
                        <mat-label>
                          <smallstack-i18n [data]="model.title" />
                        </mat-label>
                        <input matInput [(ngModel)]="customContactFieldValue.value" (ngModelChange)="updateValue()" />
                      </mat-form-field>
                    }
                  }
                </ng-template>
              </smallstack-store-model>
            }
            <button class="btn-action" (click)="addEntry()">
              <smallstack-icon name="trash" (click)="removeEntry(index)" />
            </button>
          </div>
        }
        <button class="btn-action" (click)="addEntry()">
          <smallstack-icon name="plus-math" size="12" />
        </button>
      </div>
    </div>
  </smallstack-store-container>
}
