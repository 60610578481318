import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { captureException } from "@sentry/angular-ivy";
import { CheckinVisitorDto } from "@smallstack/axios-api-client";
import { ErrorMessageHandler } from "@smallstack/common-components";
import { ContentStageBridge } from "@smallstack/content-stage-shared";
import { PublicContactsStore } from "@smallstack/crm-components";
import { Form } from "@smallstack/form-shared";
import { Store, StoreState } from "@smallstack/store";
import { FormComponent } from "@smallstack/widget-core";
import { BehaviorSubject, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { CheckinService } from "../../shared/checkin.service";
import { DeletionPeriod, SimpleDialogPageState, VisitorApp } from "../../shared/types";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./visitor-form-page.component.html"
})
export class VisitorFormPageComponent implements OnInit, OnDestroy {
  public backNavigate: string;
  public readonly backNavigateAddress: string = "../../";
  public loading: boolean = true;
  public appStore: Store<VisitorApp>;
  public form$: BehaviorSubject<Form>;
  public formData: any = {};
  public selectedDeletionPeriodDuration: number;

  @ViewChild(FormComponent)
  public formComponent: FormComponent;

  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private stageBridge: ContentStageBridge,
    private contactsStore: PublicContactsStore,
    private checkinService: CheckinService,
    private errorMessageHandler: ErrorMessageHandler
  ) {
    this.backNavigate = this.backNavigateAddress;
    this.appStore = checkinService.currentApp$;
    this.form$ = checkinService.currentForm$;
  }

  public async ngOnInit(): Promise<void> {
    this.subscription.add(
      this.appStore.value$.pipe(first((app) => app !== undefined)).subscribe((app) => {
        const periods: DeletionPeriod[] = this.appStore.value.deletionPeriods;
        if (periods && !this.selectedDeletionPeriodDuration)
          this.selectedDeletionPeriodDuration = periods.find((p) => p.isDefault)?.duration;
      })
    );

    // wait for contact persons store
    if (this.contactsStore.state === StoreState.INITIAL) await this.contactsStore.preload();
    this.loading = false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public createVisitor() {
    return async (): Promise<void> => {
      await this.formComponent.validateForm()();
      if (this.formComponent.formService.isValid() === true) {
        this.loading = true;
        try {
          const visitor: CheckinVisitorDto = { fields: this.formData };
          if (this.selectedDeletionPeriodDuration) visitor.deleteOnPreference = this.selectedDeletionPeriodDuration;
          // get default event from App
          let event: string;
          if (this.appStore.value.events instanceof Array && this.appStore.value.events.length > 0) {
            const defaultEvent = this.appStore.value.events.find((events) => events.isDefault);
            if (defaultEvent) event = defaultEvent.name;
            else event = this.appStore.value.events[0].name;
          }
          const newVisitorId: string = await this.checkinService.createVisitor(visitor, {
            appId: this.appStore.value.id,
            formId: this.form$.value.id,
            createEvent: event
          });
          const createdVisitor: CheckinVisitorDto = await this.checkinService.getVisitor(newVisitorId);
          if (this.stageBridge.isAvailable()) {
            await this.stageBridge.printVisitor(createdVisitor, createdVisitor.latestEvent);
          }

          const state: SimpleDialogPageState = {
            title: this.appStore.value?.successSignInTitle,
            message: this.appStore.value?.successSignInMessage
          };
          await this.router.navigate(["../../success"], { relativeTo: this.activatedRoute, state });
        } catch (e) {
          captureException(e);
          console.log(e);
          await this.router.navigate(["../../error"], {
            relativeTo: this.activatedRoute,
            state: {
              title: "Fehler",
              message: this.errorMessageHandler.handleMessage(e)
            }
          });
        } finally {
          this.loading = false;
        }
      }
    };
  }
}
